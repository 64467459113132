export default defineNuxtRouteMiddleware((to) => {
  const { isLoggedIn } = useUserStore()

  if (isLoggedIn) {
    return
  }

  const { routing } = useRoutingStore()
  if (!routing) {
    return abortNavigation()
  }

  const url = new URL(routing.sign_up)
  url.searchParams.append('next', to.fullPath)
  return navigateTo(url.href, { external: true })
})
